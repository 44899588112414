body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modelheader {
  height: "90px";
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background-color: rgba(206, 213, 253, 0.966);
  left: calc(50% - 217px / 2);
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  text-align: right;
  backdrop-filter: blur(4px);
  color: #414141;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
body::-webkit-scrollbar {
  width: 11px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.modal.bottom-sheet {
  min-height: 100% !important;
  min-width: 100vw !important;
}

/* .modal-content { */
  /* padding: 0px !important; */
/* } */

.modal .modal-footer {
  /* padding: 0px !important; */
  height: 72px;
}


.sidenav li.active {
  background-color: rgba(246, 178, 181, 0.2);
}

.sidenav .collapsible-header:hover,
.sidenav.fixed .collapsible-header:hover {
  background-color: #ffc8b3;
}

.sidenav li > a:hover {
  background-color: #c4e3ed;
}

.sidenav .active li > a[aria-current="page"] {
  background-color: #ebf6f9;
}

.tabs .tab > a:hover {
  background-color: rgba(246, 178, 181, 0.2);
}

.tabs .tab > a.active {
  background-color: rgba(246, 178, 181, 0.2);
}

table.striped > tbody > tr:nth-child(odd) {
  background-color: #f3ffff;
}

.chip {
  height: auto;
  margin-bottom: 0px;
}

table.responsive-table tbody::-webkit-scrollbar {
  display: inline-block;
  width: 11px;
  height: 8px;
}

table.responsive-table tbody::-webkit-scrollbar-track {
  background: #cfd8dc;
}

table.responsive-table tbody::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 6px;
  border: 2px solid #cfd8dc;
}

.progress {
  height: 2px;
}

.progress .indeterminate {
  background-color: #94bf85;
}

.bordas {
  /* border-top: 1px solid lightgray; */
  border-bottom: 0.7px solid rgba(39, 55, 134, 0.3);
  /* border-style: none none dotted none; */
  height: 110px;
  background-color: rgba(206, 213, 253, 0.3);
}

.header {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.tool-header {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  margin-top: 25px;
  margin-left: 100px;
}

.tool-header-button {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 30px;
  width: 30px;
  height: auto;
}

.title {
  /* left: calc(50% - 217px / 2); */
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;

  backdrop-filter: blur(4px);
  color: #273786;
}

.cabe {
  display: block;
}

/* Override Modal Materialize */

/* .modal h1,
.modal h2,
.modal h3,
.modal h4,
.modal h5 { */
  /* height: 60px;

  border-bottom: 0.7px solid rgba(39, 55, 134, 0.3);

  margin-bottom: 20px;
  background-color: rgba(206, 213, 253, 0.3) !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 30px !important;

  backdrop-filter: blur(4px) !important;
  color: #273786 !important; */
/* } */

@media only screen and (max-width: 600px) {
  .header {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .title {
    font-size: 1.5rem;
  }

  .tool-header {
    margin-left: 10px;
  }
}

/* label color */
.input-field label {
  color: #000 !important;
}
/* label focus color */
.input-field input[type="text"]:focus + label {
  color: #000 !important;
}

/* label underline focus color */
.input-field input[type="text"]:focus {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* valid color */
.input-field input[type="text"].valid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* invalid color */
.input-field input[type="text"].invalid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #000 !important;
}

select {
  font-size: 12px !important;
  margin: 40px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}

select:focus {
  outline: 1px solid;
}

.dropdown-content li > a,
.dropdown-content li > span {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.9) !important;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}

select option {
  font-size: 12px !important;
  margin: 40px !important;
  background: rgba(0, 0, 0, 0.9) !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.9) !important;
}

select option:focus + label {
  margin: 40px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}

/* @media only screen and (min-width: 680px) {
  html {
    zoom: 75%;
  }
} */
